import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import analysisCard from "../ToolsCard.vue";
import Button from "@/components/button";
import Input from "@/components/input";
import Loader from "@/components/loader";
import Pagination from "@/components/pagination";
import osintAxios from "@/axios/osint.js";
import DropdownAnalysis from "@/components/dropdown-analysis";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import axios from "@/axios";
import {map, uniqBy} from "lodash";
import {setUsernamesDropdownData, convertToCSV, csvFileName} from "../../../../utils/functions";
import {mapActions, mapGetters, mapMutations} from "vuex";
import NeoAnalyse from "@/components/analyse";
import JsonCSV from "vue-json-csv";
import toolsDisclaimer from "@/components/tools-disclaimer";
import {monitoringSearchData, getBackgroundStatus, getResultsID} from "@/utils/monitoringTools.js";
import toolsHelper from "@/mixins/toolsHelper";
import neoSwitch from "@/components/toggle-switch";
import {DateTime} from "luxon";
import {startCase} from "lodash";
import NeoTabsSwitch from "@/components/tabs-switch";

export default {
    name: "neo-linkedIn",
    mixins: [toolsHelper],
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-pagination": Pagination,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-loader": Loader,
        "neo-tabs-switch": NeoTabsSwitch,
        "neo-dropdown-analysis": DropdownAnalysis,
        VueMultiselect,
        "analysis-card": analysisCard,
        NeoAnalyse,
        "download-csv": JsonCSV,
        toolsDisclaimer,
        neoSwitch,
    },
    props: {
        toolData: {
            type: Array,
            default: () => [],
        },
        tool: {
            type: String,
            default: "",
        },
        toolSearchQueries: Array,
    },
    data() {
        return {
            case_id: "",
            inputValue: {
                text: "Email",
                value: "Email",
            },
            inputOptions: [
                {
                    group: "Field Type",
                    categories: [
                        {
                            text: "Email",
                            value: "Email",
                        },
                        {
                            text: "URL",
                            value: "URL",
                        },
                        {
                            text: "Domain",
                            value: "Domain",
                        },
                    ],
                },
            ],
            tabs: [
                {
                    name: "Details",
                    value: "details",
                },
                {
                    name: "Discovery",
                    value: "discovery",
                },
            ],
            selectedTab: "",
            searchKeyword: "",
            searchEmail: "",
            // selectedTab: "",
            allData: [],
            csvData: {
                linkedin: [],
            },
            domain: false,
            social_platforms: null,
            domainData: {},
            lazyLoading: false,
            currentPage: 1,
            isLoading: false,
            isDomainLoading: false,
            companyName: "",
            nameKeyword: "",
            selectedName: null,
            selectedItem: null,
            selectedEmail: null,
            searchType: "",
            triggerRoute: true,
            emailList: [],
            domainList: [],
            nameList: [],
            urlList: [],
            isQueryValid: false,
            showResult: false,
            selectVal: "",
            selectNameVal: "",
            selectEmailVal: "",
            searchClicked: false,
            tabName: "",
            empStartDate: [],
            empEndDate: [],
            eduStartDate: [],
            eduEndDate: [],
            request_id: "",
            discoveryDetails: [],
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getPermissions", "getResearchData", "getAllToolsData", "getCaseEmails", "getCaseDomains", "getMonitoringDateFilter", "getSelectedToolQuery", "getProduct", "getAllMonitoringData"]),

        fetchAllData() {
            if (this.getResearchData.personalDetails?.length || this.getResearchData.socialProfiles?.length) {
                this.getToolData();
            }
        },
        ifAuth() {
            return this.getPermissions.includes("linkedin_discover__social_media");
        },

        ifLinkedinTool() {
            return this.getPermissions.includes("linkedin__social_media");
        },

        query() {
            return this.selectedItem?.value || `${this.selectedName?.value}${this.companyName}` || this.selectedEmail?.value;
        },

        csvFields() {
            return ["profile_pic", "name", "current_work_email", "current_personal_email", "location", "current_employer", "current_title", "teaser", "emails", "phones", "internalLinks"];
        },

        linkedInAllData() {
            return this.allData;
        },

        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },
        checkMarkAllDone() {
            if (this.linkedInAllData && this.linkedInAllData.length) return this.linkedInAllData.every((result) => result.changes_viewed);
            else return false;
        },
    },
    watch: {
        "$route.query"(to, from) {
            if (this.triggerRoute) {
                this.redirectToQuery();
            }
        },

        selectedTab: {
            handler: function(newSelectedTab) {
                // if (newSelectedTab === "details") {
                //     this.clearFields()
                // }
                // else if (newSelectedTab === "discovery")
                // this.clearDiscoveryFileds()
            },
        },

        // companyName() {
        //     this.showResult = false;
        // },

        // selectedName: {
        //     handler(val) {
        //         this.showResult = false;
        //     },
        //     deep: true,
        // },

        // selectedItem: {
        //     handler(val) {
        //         this.showResult = false;
        //     },
        //     deep: true,
        // },
    },
    async mounted() {
        this.getSelectedTool();
        this.tabName = this.$route.name;
        this.case_id = this.$route.params.id;
        const dataRes = this.getToolsData(this.tabName, "linkedin-tool");
        const {data: social_platforms} = await axios.get(`social_platforms`);
        this.social_platforms = social_platforms;
        this.$nextTick(function() {
            document.getElementById("case-list").addEventListener("scroll", this.onScroll, false);
        });
        if (dataRes) this.redirectToQuery(dataRes);
        this.getToolData();
    },
    beforeDestroy() {
        this.setToolsData(this.tabName, "linkedin-tool");
        document.getElementById("case-list").removeEventListener("scroll", this.onScroll);
    },
    methods: {
        getBackgroundStatus,
        getResultsID,
        ...mapMutations(["SET_ALL_TOOLS_DATA", "SET_ALL_MONITORING_DATA"]),
        ...mapActions(["getAllCaseEmails", "getAllCaseDomains"]),

        async getToolData() {
            this.emailList = await this.getDropdownData("email");
            this.domainList = await this.getDropdownData("domain");
            this.nameList = await this.getDropdownData("name");
            this.urlList = await this.getDropdownData("url");
        },

        async getDropdownData(field) {
            let data = this.toolData.filter((user) => user.val_type === field);
            return data;
        },

        getOptionsList(list) {
            return [
                {
                    group: list.split("List")[0],
                    categories: this[list],
                },
            ];
        },

        handleChecked(data) {
            let allChecked = false;
            this.$emit("updateToolData", "linkedin", [...this.emailList, ...this.domainList, ...this.nameList, ...this.urlList], data);
            allChecked = [...this.emailList, ...this.domainList, ...this.nameList, ...this.urlList]?.length ? ([...this.emailList, ...this.domainList, ...this.nameList, ...this.urlList].findIndex((n) => !n.visited) > -1 ? false : true) : false;

            this.$emit("handleChecked", allChecked);
        },

        handleCheckedDiscover(data) {
            let allChecked = false;
            this.$emit("updateToolData", "linkedin-discover", [...this.emailList], data);
            allChecked = [...this.emailList]?.length ? ([...this.emailList].findIndex((n) => !n.visited) > -1 ? false : true) : false;
            this.$emit("handleChecked", allChecked);
        },

        convertToCSV(arr) {
            return convertToCSV(arr);
        },

        csvFileName(query, section) {
            return csvFileName(this.tool, query, section);
        },

        updateFieldType() {
            this.selectedItem = null;
            this.selectedEmail = null;
            this.nameKeyword = "";
            this.companyName = "";
            this.searchKeyword = "";
            this.selectedName = null;
            this.showResult = false;
            this.searchClicked = false;
        },

        clearFields() {
            this.selectedItem = null;
            this.nameKeyword = "";
            this.companyName = "";
            this.searchKeyword = "";
            this.selectedName = null;
            this.$refs["analysis-tool-input"].search = "";
            if (this.$refs["analysis-tool-input-name"]) this.$refs["analysis-tool-input-name"].search = "";
            this.selectVal = "";
            this.request_id = "";
            this.redirectTo();
        },
        clearDiscoveryFileds() {
            this.selectedEmail = null;
            this.searchEmail = "";
            this.discoveryDetails = [];
            if (this.$refs["analysis-tool-input-name"]) this.$refs["analysis-tool-input-name"].search = "";
            this.selectEmailVal = "";
            this.request_id = "";
            this.redirectTo();
        },
        setInput() {
            this.searchKeyword = this.selectedItem.value;
            // this.search();
        },
        setTheCustomInput(value) {
            this.searchKeyword = value;
            this.selectedItem = {
                value: value,
            };
            // this.search();
        },
        setEmail() {
            this.searchEmail = this.selectedEmail.value;
            // this.search();
        },
        setTheCustomEmail(value) {
            this.searchEmail = value;
            this.selectedEmail = {
                value: value,
            };
            // this.search();
        },
        setName() {
            this.nameKeyword = this.selectedName.value;
            // this.search();
        },
        setTheCustomName(value) {
            this.nameKeyword = value;
            this.selectedName = {
                value: value,
            };
            // this.search();
        },
        manualSearch() {
            this.triggerRoute = false;
            this.search();
        },

        async search() {
            this.isQueryValid = false;
            // this.discoveryDetails = [];
            this.domain = false;
            this.domainData = {};
            this.searchType = "";
            this.allData = [];
            this.isLoading = true;
            this.csvData = {
                linkedin: [],
            };
            this.searchClicked = true;
            if (this.searchKeyword || this.nameKeyword || this.companyName) {
                const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
                const urlRegex = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
                const domainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
                let url = "";
                let bodyData = {};
                let searchType = "";

                if (this.inputValue.value == "Email" && this.selectedItem && emailRegex.test(this.searchKeyword)) {
                    this.isQueryValid = true;
                    url = "/linkedin/search/email";
                    bodyData = {
                        email: this.searchKeyword,
                    };
                    searchType = "email";
                    this.$emit("searched", {
                        data: {email: this.searchKeyword},
                        source: "linkedin",
                        searchType: "email",
                        type: "username_search_key", // NOTE: chnage this to subject key later on
                    });
                    await this.setSearchedLinkedinUser(this.searchKeyword, "email");
                } else if (this.inputValue.value == "URL" && this.selectedItem && urlRegex.test(this.searchKeyword)) {
                    this.isQueryValid = true;
                    url = "/linkedin/search/url";
                    bodyData = {
                        linkedin_url: this.searchKeyword,
                    };
                    searchType = "url";
                    this.$emit("searched", {
                        data: {url: this.searchKeyword},
                        source: "linkedin",
                        searchType: "url",
                        type: "username_search_key", // NOTE: chnage this to subject key later on
                    });
                    await this.setSearchedLinkedinUser(this.searchKeyword, "url");
                } else if (this.inputValue.value == "Domain" && this.selectedItem && domainRegex.test(this.searchKeyword)) {
                    this.isQueryValid = true;
                    url = "/linkedin/search/domain";
                    bodyData = {
                        domain: this.searchKeyword,
                        start: 1,
                        page_size: 10,
                    };
                    this.domainData = bodyData;
                    this.domain = true;
                    searchType = "domain";
                    this.$emit("searched", {
                        data: {domain: this.searchKeyword},
                        source: "linkedin",
                        searchType: "domain",
                        type: "username_search_key", // NOTE: chnage this to subject key later on
                    });
                    await this.setSearchedLinkedinUser(this.searchKeyword, "domain");
                } else {
                    if (this.nameKeyword && this.companyName) {
                        this.isQueryValid = true;
                        url = "/linkedin/search/name/company";
                        searchType = "username";
                        this.$emit("searched", {
                            data: {user: this.nameKeyword},
                            source: "linkedin",
                            searchType: "username",
                            type: "username_search_key", // NOTE: chnage this to subject key later on
                        });
                        bodyData = {
                            name: this.nameKeyword,
                            company: this.companyName,
                        };
                        await this.setSearchedLinkedinUser(this.nameKeyword, this.selectedName?.val_type || "name");
                    } else if ((!this.nameKeyword || !this.companyName) && !this.searchKeyword) {
                        this.$toast.error("Name and Company both are required");
                        return;
                    }
                }
                this.searchType = searchType;

                // IN MONITORING TAB FETCH MONITORING QUERY RESULTS
                if (this.isMonitoringTab) {
                    await this.monitoringSearch();
                    return;
                }

                if (this.isQueryValid) {
                    this.showResult = false;
                    if (url) {
                        const {data} = await osintAxios.post(url, bodyData, {
                            headers: {
                                "x-tool-name": this.$route.params.toolName,
                                "x-visited": true,
                            },
                        });
                        this.allData = [];
                        if (data.status) {
                            let searchResultData = data.data;
                            if (Object.keys(searchResultData).includes("profile_list")) {
                                delete searchResultData.profile_list;
                            }
                            this.$emit("searchResult", {data: searchResultData, source: "linkedin", searchType: searchType});
                            if (this.domain) {
                                this.allData = data?.data;
                                this.csvData.linkedin = this.convertToCSV(this.allData);
                                for (const info of data?.data) {
                                    await this.fetchLinksIcon(info.id);
                                }
                            } else if (this.nameKeyword && this.companyName) {
                                this.allData = data?.data?.profiles;
                                this.csvData.linkedin = this.convertToCSV(this.allData);
                                for (const info of data?.data?.profiles) {
                                    await this.fetchLinksIcon(info.id);
                                }
                            } else {
                                this.allData.push(data?.data);
                                this.csvData.linkedin = this.convertToCSV(this.allData);
                                await this.fetchLinksIcon(data?.data.id);
                            }
                            // this.$emit("searchResult", {
                            //     data: newValue,
                            //     source: "linkedin",
                            //     searchType: this.searchType,
                            // });
                            this.showResult = true;
                            if (this.allData[0]?.job_history?.length > 0) {
                                this.allData[0].job_history = this.allData[0].job_history.map((emp) => {
                                    let empStartDt = DateTime.fromISO(emp.start_date);
                                    empStartDt = empStartDt.toFormat("dd MMM yyyy");
                                    emp.start_date = emp.start_date ? empStartDt : "-";
                                    if (emp.end_date != "Present") {
                                        let empEndDt = DateTime.fromISO(emp.end_date);
                                        empEndDt = empEndDt.toFormat("dd MMM yyyy");
                                        emp.end_date = emp.end_date ? empEndDt : "-";
                                    } else {
                                        emp.end_date = emp.end_date;
                                    }
                                    return emp;
                                });
                            }
                        } else {
                            this.$toast.error(String(data.message));
                        }
                        this.$emit("searchResult", {data: [], source: "linkedin", searchType: searchType});
                        this.request_id = data?.query_id;
                        this.redirectTo();
                    }
                } else {
                    this.$toast.error("Please enter a valid input");
                }
                this.triggerRoute = true;
                this.isLoading = false;
                this.SET_ALL_TOOLS_DATA({
                    ...this._data,
                    "tool-name": "linkedin-tool",
                });
            } else {
                this.$toast.error("Please enter a valid input");
            }
        },

        getSelectedTool() {
            if (this.getPermissions.includes("linkedin_discover__social_media") && this.getPermissions.includes("linkedin__social_media")) {
                this.selectedTab = "details";
            } else if (this.getPermissions.includes("linkedin__social_media")) {
                this.selectedTab = "details";
            } else if (this.getPermissions.includes("linkedin_discover__social_media")) {
                this.selectedTab = "discovery";
            }
        },

        async discoverySearch() {
            this.isLoading = true;
            this.discoveryDetails = [];
            if (this.searchEmail) {
                const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
                let searchType = "";
                if (this.selectedEmail && emailRegex.test(this.searchEmail)) {
                    this.isQueryValid = true;
                    let bodyData = {
                        discover_email: this.searchEmail,
                        discovery: true,
                    };
                    searchType = "email";
                    this.$emit("searched", {
                        data: {email: this.searchEmail},
                        source: "linkedin-discover",
                        searchType: "email",
                        type: "username_search_key", // NOTE: chnage this to subject key later on
                    });
                    await this.setSearchedLinkedinDiscoverUser(this.searchEmail, "email");
                    if (this.searchEmail) {
                        this.showResult = false;

                        const {data} = await osintAxios.post("/intel/linkedin/discover", bodyData, {
                            headers: {
                                "x-tool-name": this.$route.params.toolName,
                                "x-visited": true,
                            },
                        });
                        if (data && data.message) {
                            this.isLoading = false;
                            this.discoveryDetails = data;
                        } else if (data?.data && data?.data?.profile) {
                            this.isLoading = false;
                            this.discoveryDetails = data.data;
                        }
                        this.$emit("searchResult", {data: this.discoveryDetails, source: "linkedin-discover", searchType: "email"});
                        this.request_id = data?.query_id;
                        this.redirectTo();
                    }
                } else {
                    this.$toast.error("Please enter a valid input");
                }
            } else {
                this.$toast.error("Please enter a valid input");
            }
            this.triggerRoute = true;
            this.isLoading = false;
            this.SET_ALL_TOOLS_DATA({
                ...this._data,
                "tool-name": "linkedin-tool",
            });
        },

        async setSearchedLinkedinUser(searchKeyword, val_type) {
            const data = {
                value: searchKeyword,
                platform: "linkedin",
                val_type,
                visited: true,
            };
            let url = `research-methodology/${this.case_id}/tools/profiles`;
            let isSuccess = await setUsernamesDropdownData(url, data);
            // if (isSuccess) {
            let type = val_type;
            let dataItem = this[`${type}List`].find((dataItem) => {
                return dataItem.value === searchKeyword;
            });
            if (dataItem) {
                dataItem["visited"] = true;
                this.handleChecked(data);
            } else if (data?.value) {
                this[`${type}List`].push(data);
                this.$emit("addCustomData", data);
            }
            // }
        },
        async setSearchedLinkedinDiscoverUser(searchKeyword, val_type) {
            const data = {
                value: searchKeyword,
                platform: "linkedin-discover",
                val_type,
                visited: true,
            };
            let url = `research-methodology/${this.case_id}/tools/profiles`;
            let isSuccess = await setUsernamesDropdownData(url, data);
            // if (isSuccess) {
            let type = val_type;
            let dataItem = this[`${type}List`].find((dataItem) => {
                return dataItem.value === searchKeyword;
            });
            if (dataItem) {
                dataItem["visited"] = true;
                this.handleCheckedDiscover(data);
            } else if (data?.value) {
                this[`${type}List`].push(data);
                this.$emit("addCustomData", data);
            }
            // }
        },
        async fetchLinksIcon(id) {
            let index = this.allData.findIndex((n) => n.id == id);
            let links = [];
            if (!this.social_platforms) {
                const {data: social_platforms} = await axios.get(`social_platforms`);
                this.social_platforms = social_platforms;
            }
            if (this.allData[index].links) {
                for (const link of Object.keys(this.allData[index].links)) {
                    const data = this.social_platforms.find((n) => n.key == link);
                    let obj = {
                        link: this.allData[index].links[link],
                        link_name: link,
                    };
                    if (data) {
                        obj.icon = data.icon_data;
                        obj.link_name = data.name;
                    }
                    links.push(obj);
                }
            } else {
                const data = this.social_platforms.find((n) => n.key == "linkedin");
                let obj = {
                    link: this.allData[index].linkedin_url,
                    icon: data.icon_data,
                    link_name: data.name,
                };
                links.push(obj);
            }
            this.allData[index].internalLinks = links;
            this.$forceUpdate();
        },
        async onScroll() {
            if (this.domain) {
                let header = document.getElementById("case-list");
                if (header.scrollTop > header.scrollHeight - header.offsetHeight - 400) {
                    if (!this.lazyLoading) {
                        await this.getDomainData();
                    }
                }
            }
        },
        async getDomainData() {
            this.lazyLoading = true;
            this.showResult = false;
            this.currentPage += 1;
            this.domainData.start = this.currentPage * 10 - 10 + 1;
            this.isDomainLoading = true;
            try {
                const {data} = await osintAxios.post("/linkedin/search/domain", this.domainData, {
                    headers: {
                        "x-tool-name": this.$route.params.toolName,
                        "x-visited": true,
                    },
                });
                if (data.data.length <= 0) {
                    this.lazyLoading = true;
                    this.isDomainLoading = false;
                    return;
                }
                this.allData = this.allData.concat(data?.data);
                this.csvData.linkedin = this.convertToCSV(this.allData);
                this.showResult = true;
                // this.$emit("searchResult", { data: this.allData, source: "linkedin", searchType: "domain" });

                for (const info of data?.data) {
                    await this.fetchLinksIcon(info.id);
                }
                this.lazyLoading = false;
                this.isDomainLoading = false;
                this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "linkedin-tool"});
            } catch (error) {
                console.error("Error!", {error});
            }

            // loader.hide();
        },

        redirectTo() {
            let query = {};
            if (this.request_id)
                query = {
                    query_id: this.request_id,
                };
            this.$router.push({path: this.$route.path, query}).catch((err) => {});
        },

        redirectToQuery(dataRes) {
            // redirect to same query
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);
                const value1 = Object?.keys(selected_query?.query)[0];
                const value2 = Object?.keys(selected_query?.query)[1];

                if (value1 === "email" || value1 === "domain" || value1 === "linkedin_url") {
                    this.searchKeyword = Object.values(selected_query?.query)[0];
                    this.selectedItem = {
                        value: Object.values(selected_query?.query)[0],
                    };
                    this.inputValue = {
                        text: startCase(Object.keys(selected_query?.query)[0]) == "Linkedin Url" ? "URL" : startCase(Object.keys(selected_query?.query)[0]),
                        value: startCase(Object.keys(selected_query?.query)[0]) == "Linkedin Url" ? "URL" : startCase(Object.keys(selected_query?.query)[0]),
                    };
                    this.selectVal = Object.values(selected_query?.query)[0];
                } else if (value2 === "name" || value1 === "company") {
                    this.nameKeyword = Object.values(selected_query?.query)[1];
                    this.companyName = Object.values(selected_query?.query)[0];
                    this.selectedName = {
                        value: Object.values(selected_query?.query)[1],
                    };
                    this.selectNameVal = Object.values(selected_query?.query)[1];
                } else if (value1 === "discovery") {
                    this.searchEmail = Object.values(selected_query?.query)[1];
                    this.selectedEmail = {
                        value: Object.values(selected_query?.query)[1],
                    };
                    this.selectEmailVal = Object.values(selected_query?.query)[1];
                }
            } else {
                this.redirectTo();
            }
        },
        onSelect(event, type) {
            console.log(type);
            if (type == "name") {
                this.selectNameVal = event.value;
                this.$refs["analysis-tool-input-" + type].search = event.value;
            } else {
                this.selectVal = event.value;
                this.$refs["analysis-tool-input"].search = event.value;
            }
        },

        onSelectDiscovery(event, type) {
            this.selectEmailVal = event.value;
            this.$refs["analysis-tool-input"].search = event.value;
        },

        close(type) {
            if (type == "name") {
                this.selectedName = {
                    value: this.selectNameVal,
                };
                this.nameKeyword = this.selectNameVal.trim();
                this.$refs["analysis-tool-input-" + type].search = this.selectNameVal.trim();
            } else {
                this.selectedItem = {
                    value: this.selectVal.trim(),
                };
                this.searchKeyword = this.selectVal.trim();
                this.$refs["analysis-tool-input"].search = this.selectVal;
            }
        },
        closeDiscovery(type) {
            this.selectedEmail = {
                value: this.selectEmailVal.trim(),
            };
            this.searchEmail = this.selectEmailVal.trim();
            this.$refs["analysis-tool-input"].search = this.selectEmailVal;
        },
        handleChange(event, type) {
            if (type == "name") {
                if (event != this.selectNameVal) {
                    this.showResult = false;
                    this.searchClicked = false;
                }
                this.selectNameVal = event;
            } else {
                if (event != this.selectVal) {
                    this.showResult = false;
                    this.searchClicked = false;
                }
                this.selectVal = event;
            }
            // this.$set(this.selectVal, type,event)
        },
        handleDiscoverChange(event, type) {
            if (event != this.selectEmailVal) {
                this.showResult = false;
                this.searchClicked = false;
            }
            this.selectEmailVal = event;
            // this.$set(this.selectVal, type,event)
        },
        onSelectFieldType() {
            this.selectedItem = null;
            this.$refs["analysis-tool-input"].search = "";
            this.selectVal = "";
        },
        onInput() {
            this.showResult = false;
            this.searchClicked = false;
        },
        /**
         * Fired when search queries are clicked && monitoring tab is active
         */
        async monitoringSearch() {
            let sources = ["linkedin"];
            this.showResult = true;
            this.isLoading = true;
            this.allData = [];
            let values = await monitoringSearchData(this.case_id, sources, this.$route.params.toolName, this.getMonitoringDateFilter, this.getSelectedToolQuery);
            // this.redirectToQuery()
            this.allData = values;
            this.csvData.linkedin = this.convertToCSV(this.allData);
            this.isLoading = false;
            // this.searchKeyword = this.inputValue.value.toLowerCase()
        },

        async handleMonitoring(index) {
            let sources = "linkedin-tool";

            this.allData[index].monitoring_status = !this.allData[index].monitoring_status;
            this.$forceUpdate();
            await axios.post("/data-monitoring", {
                product_name: this.getProduct.name,
                product_id: this.getProduct.key,
                case_id: this.$route.params.id,
                query: this.getSelectedToolQuery,
                tool: this.$route.params.toolName,
                frequency: 7,
                monitoring_type: "card",
                inherit_product_settings: false,
                inherit_product_id: "",
                card_id: this.allData[index].doc_id,
                card_source: sources,
                status: this.allData[index].monitoring_status ? "enabled" : "disabled",
            });
        },

        async handleDone({target}, index) {
            this.allData[index].changes_viewed = !this.allData[index].changes_viewed;
            this.$forceUpdate();
            let sources = ["linkedin-tool"];
            await axios.post("/data-monitoring/mark-monitered-data-visited", {
                sources,
                doc_id: this.allData[index].monitoring_id,
                visited: target.checked,
            });
        },

        async handleMarkAsAllDone(event) {
            this.allData.forEach((res, index) => {
                this.allData[index].changes_viewed = event.target.checked;
            });
            this.$forceUpdate();
            await axios.post("/data-monitoring/mark-monitered-data-visited/query/all", {
                sources: ["linkedin-tool"],
                mark_all_as_visited: event.target.checked,
                query_id: this.getSelectedToolQuery.query_id,
            });
        },
        getFormattedTime(ts) {
            let date = DateTime.fromISO(ts);
            return date.toFormat("dd MMM yyyy");
        },
    },
};
